<template>
  <div class="flex-wrapper-app">
    <Navbar />
    <main class="review-main">
      <Loader v-if="isLoading" />

      <div v-else>
        <review-body
          :exists="exists"
          :voucher="voucher"
          :isLockerOrder="isLockerOrder"
          :isReturn="isReturn"
          :errorMessage="errorMessage"
        />
      </div>
    </main>
    <Footer class="footer" />
  </div>
</template>

<script>
import axios from 'axios';

import Navbar from '../pages-sections/Navbar.vue';
import ReviewBody from '../pages-sections/ReviewBody.vue';
import Loader from '../loader/Loader.vue';
import Footer from '../pages-sections/Footer.vue';

export default {
  name: 'ReviewPage',
  components: {
    Navbar,
    ReviewBody,
    Loader,
    Footer,
  },
  data() {
    return {
      voucher: '',
      isLockerOrder: true,
      isReturn: false,
      exists: false,
      review: {},
      isLoading: false,
      errorMessage: null,
    };
  },
  mounted() {
    this.voucher = this.$route.params?.voucher;
    if (this.voucher) {
      this.reviewAlreadyExists();
    }
  },
  methods: {
    async reviewAlreadyExists() {
      this.isLoading = true;
      const response = await axios
        .get(
          `${process.env.VUE_APP_COURIER_API}/user/review/${this.voucher}`,
          // `http://localhost:8000/user/review/${this.voucher}`
        )
        .catch(err => {
          if (err.response.status === 403) {
            this.exists = true;
          } else {
            this.errorMessage = err.response?.data?.error_description;
          }
          this.isLoading = false;
        });

      if (response && response.status === 200) {
        this.exists = false;
        this.isLockerOrder = response.data.isLockerOrder === true ? true : false;
        this.isReturn = response.data.isReturn;
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #fff;
$orange: #f68b24;
$orange8: rgb(246 139 36 / 80%);
$black: #363636;
$warning: #f44336;

.review-main {
  background-size: cover !important;
}

.footer {
  bottom: 0;
  left: 0;
}

@mixin flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 733px) {
  .review-main {
    padding: 15px 0 224px;
    //margin-left: 55px;
  }
  form input {
    font-size: 14px;
  }
}

@media screen and (min-width: 733px) and (max-width: 961px) {
  .review-main {
    padding: 50px 0 433px;

    .container {
      margin: 0 59px;
    }
  }
}

@media screen and (max-width: 961px) {
  .review-main {
    justify-content: center;
    width: 100%;

    .container {
      width: 100%;
    }
  }
}

@media screen and (min-width: 961px) and (max-width: 1500px) {
  .container {
    margin-left: 64px !important;
  }
  .review-main {
    padding: 100px 0 256px;
  }
}

@media screen and (min-width: 1500px) {
  .container {
    margin-left: 64px !important;
  }
  .review-main {
    padding: 100px 0 256px;
  }
}
</style>
